import React from "react";

export const calendlyUrl = "https://calendly.com/waldenyan20/meeting";

export const socials = [
  {
    name: "LinkedIn",
    url: "https://www.linkedin.com/in/waldenyan",
    image: "/icons/linkedin.svg",
  },
  {
    name: "GitHub",
    url: "https://github.com/walnutwaldo",
    image: "/icons/github.svg",
  },
  {
    name: "Twitter",
    url: "https://twitter.com/walden_yan",
    image: "/icons/twitter.svg",
  },
  {
    name: "Writing",
    url: "https://waldensthoughts.bloggi.co/",
    image: undefined,
  },
];

export const heroText = (
  <p>
    {/* Hey! I'm <b>Walden</b>. I spend my time building cool products and tools */}
    {/* with modern technology. I also love working with teams of other ambitious */}
    {/* people to tackle modern problems. */}
    1. Using software to advance humanity
    <br />
    2. Working with exceptional people
  </p>
);

export const heroLines = [
  "What drives me",
  // "Working with exceptional teams",
  // "Love for engineering",
];
